
import './providers.scss'
import NotFound from "../../components/NotFound/NotFound";
import { useEffect, useState } from 'react';
import BreadCumb from '../../components/BreadCumb/BreadCumb';
import FormComponent from '../../components/FormComponent/FormComponent';
import TableComponent from '../../components/TableComponent/TableComponent'
import { getAction, transformDataForTableComponent } from '../../actions/default.actions';
import { endpointsMapping } from '../../constants/endpoints.types';
import { formsMapping } from '../../constants/formDatas.types';
import { tablesMapping } from "../../constants/tablesSettings.types";

const Providers = () => 
{
	const [tableComponentKey, settableComponentKey] = useState("providers");
	const [currentOption, setcurrentOption] = useState("list");
	const [record, recordSetter] = useState(null);
	const [retreivedData, setRetrievedData] = useState([]);
	const [formData, setFormData] = useState(null);
	const [formContent, setFormContent] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [dummyReset, setdummyReset] = useState(true);
	const [addedOrUpdatedData, setAddedOrUpdatedData] = useState({state: '', data:{}});
	const [bread, setBread] = useState([{label: "providers", key: 'providers1'}]);
	const tableIcon = (
		<svg viewBox="0 0 24 24">
			<path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
		</svg>
	)
	const settings = tablesMapping.get(tableComponentKey);
	const _retrieveData = () => {
		const providersPromise = new Promise(async (resolve, reject) => {
			const response = await getAction(endpointsMapping.get(tableComponentKey)['get']);
			resolve(response);
		})
		const countriesPromise = new Promise(async (resolve, reject) => {
			const response = await getAction(endpointsMapping.get('countries')['get']);
			resolve(response);
		})
		return [providersPromise, countriesPromise]; 
	}
	useEffect(() => {
		const countries = [];
		Promise.all(_retrieveData()).then((values) => {
			const data = values[0];
			const temp = values[1]?.data;
			for (let index = 0; index < temp.length; index++) {
				countries.push({
					label: temp[index]?.name,
					key: temp[index]?._id,
				});				
			}
			if(data?.data.length > 0)
			{
				setRetrievedData(data.data);
				const transData = transformDataForTableComponent(settings, data?.data);
				setDataSource(transData);
			}
		});
		setFormContent(formsMapping.get(tableComponentKey).resolveMapping(countries));
	}, [])
	
	useEffect(() => {
		if (record) {
			for (let index = 0; index < formContent.length; index++) {
				const element = formContent[index];
				element.defaultValue = record?.[element?.['label']]
			}
		}		
	}, [record])
	useEffect(() => {
		if (currentOption === "list") {
			for (let index = 0; index < formContent.length; index++) {
				const element = formContent[index];
				delete element.defaultValue;
			}
		}
	  }, [currentOption])
	  useEffect(() => {
		if (addedOrUpdatedData.state !== "") {
			if (addedOrUpdatedData.state === "create") {
				setRetrievedData([...retreivedData, addedOrUpdatedData.data])
				setDataSource([...dataSource, ...transformDataForTableComponent(settings, [addedOrUpdatedData.data])]);
				setcurrentOption('list');
			}
			if (addedOrUpdatedData.state === "update") {
				const foundIndex = retreivedData.findIndex(data => data._id === addedOrUpdatedData.data._id);
				const temp = [...retreivedData];
				temp[foundIndex] = addedOrUpdatedData.data;
				const transData = transformDataForTableComponent(settings, temp);
				setDataSource(transData);
				setcurrentOption('list');
			}
			if (addedOrUpdatedData.state === "delete") {
				const foundIndex = retreivedData.findIndex(data => data._id === addedOrUpdatedData.data._id);
				const temp = [...retreivedData];
				temp.splice(foundIndex, 1);
				const transData = transformDataForTableComponent(settings, temp);
				setDataSource(transData);
				setTimeout(() => {
					setcurrentOption('add');
					setcurrentOption('list');
				}, 100);
			}
		}
	  }, [addedOrUpdatedData])
	useEffect(() => {
		document.querySelector('.outlet').style.overflowY = "auto";
	}, [])
	
	return(
		<div className="providersWrapper custom-wrapper">
			<BreadCumb situation={bread} />
			<div className='spacer'></div>
			{
				currentOption === "list" && dataSource.length > 0 ?
				<TableComponent
					iamgePath={'imageBase'}
					key={tableComponentKey}
					bread={bread}
					requestState={setAddedOrUpdatedData}
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					recordSetter={recordSetter} 
					icon={tableIcon} 
					settings={settings} 
					dataSource={dataSource} 
					canEdit={true} 
					canDelete={true} 
					canAdd={true}
					currentKey={tableComponentKey}
					canUpload={true}
				/>
				:
				currentOption === "add" ?
				<FormComponent
					key={"clients-formContent"} 
					formContent={formContent} 
					setFormData={setFormData} 
					bread={bread} 
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					post={endpointsMapping.get(tableComponentKey)['post']}
					type="post"
					requestState={setAddedOrUpdatedData}
				/>
				:
				dataSource.length > 0 ?
				<FormComponent 
					formContent={formContent} 
					formData={formData} 
					setFormData={setFormData} 
					bread={bread} 
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					record={record}
					put={endpointsMapping.get(tableComponentKey)['put']}
					type="put"
					requestState={setAddedOrUpdatedData}
				/>
				:
				<>
					<TableComponent bread={bread} setBread={setBread} tabSetter={setcurrentOption} recordSetter={recordSetter} icon={tableIcon} settings={settings} dataSource={dataSource} canEdit={false} canDelete={true} canAdd={true} />
					<NotFound/>
				</>
			}
		</div>
	)
}

export default Providers