export const endpointsMapping = new Map();

endpointsMapping.set('usersAdmin', {
    'get': 'api/users',
    'delete': 'api/users',
    'post': 'api/users',
    'put': 'api/users'
})

endpointsMapping.set('countries', {
    'get': 'api/countries',
    'delete': 'api/countries',
    'post': 'api/countries',
    'put': 'api/countries'
})

endpointsMapping.set('towns', {
    'get': 'api/towns',
    'delete': 'api/towns',
    'post': 'api/towns',
    'put': 'api/towns'
})

endpointsMapping.set('tickets', {
    'get': 'api/tickets',
    'delete': 'api/tickets',
    'post': 'api/tickets',
    'put': 'api/tickets'
})

endpointsMapping.set('providers', {
    'get': 'api/providers',
    'delete': 'api/providers',
    'post': 'api/providers',
    'put': 'api/providers',
    'upload': 'api/providers/upload'
})

endpointsMapping.set('documents', {
    'get': 'api/travelDocuments',
    'delete': 'api/travelDocuments',
    'post': 'api/travelDocuments',
    'put': 'api/travelDocuments'
})
