export const formsMapping = new Map();

formsMapping.set('usersAdmin',{ 
    resolveMapping: (data) => {
        return [
            { label: "fullName", type: 'text', placeholder: 'Nom.' },
            { label: "email", type: 'text', placeholder: 'Mail.' },
            { label: "password", type: 'text', placeholder: 'Mot de passe.' },
        ]
    }}
)

formsMapping.set('countries',{ 
    resolveMapping: (data) => {
        return [
            { label: "name", type: 'text', placeholder: 'Nom.' },
            { label: "countryPhonePrefix", type: 'text', placeholder: 'Préfix.' },
        ]
    }}
)

formsMapping.set('towns',{ 
    resolveMapping: (data) => {
        return [
            { label: "name", type: 'text', placeholder: 'Nom.' },
            { label: "latitude", type: 'text', placeholder: 'Latitude.' },
            { label: "longitude", type: 'text', placeholder: 'Longitude.' },
            { label: "country", type: 'select', placeholder: 'Pays.', values: data },
        ]
    }}
)

formsMapping.set('tickets',{ 
    resolveMapping: (data, days, providers) => {
        return [
            { label: "source", type: 'select', placeholder: 'Source.', values: data },
            { label: "destination", type: 'select', placeholder: 'Destination.', values: data },
            { label: "day", type: 'select', placeholder: 'Jour.', values: days},
            { label: "provider", type: 'select', placeholder: 'Compagnie.', values: providers},
            { label: "price", type: 'number', placeholder: 'Prix.' },
        ]
    }}
)

formsMapping.set('providers',{ 
    resolveMapping: (data) => {
        return [
            { label: "name", type: 'text', placeholder: 'Nom.' },
            { label: "departure", type: 'area', placeholder: 'Départs.' },
            { label: "arrival", type: 'area', placeholder: 'Arrivals.' },
            { label: "country", type: 'select', placeholder: 'Pays.', values: data },
            { label: "phoneNumber", type: 'number', placeholder: 'Téléphone',},
        ]
    }}
)

formsMapping.set('documents',{ 
    resolveMapping: (data) => {
        return [
            { label: "name", type: 'text', placeholder: 'Nom.' },
        ]
    }}
)