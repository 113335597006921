
import './FormComponent.scss'
import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { postActions, putActions } from '../../actions/default.actions';
import { showToast } from '../../constants/utils';

const FormComponent = ({formContent, tabSetter, bread, setBread, setFormData, type, post, put, requestState, record}) => 
{
	const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm();
	const [showSubmit, setshowSubmit] = useState(true);
	const onSubmit = async (data) => {
		try {
			if (type === 'post') {
				const response = await postActions(post, data);
				showToast(response?.data?.message);
				requestState({
					state: 'create',
					data: response.data.data
				});
			} else {
				if (record._id) {
					data.id = record._id;
					const response = await putActions(put, data);
					showToast(response.data.message);
					requestState({
						state: 'update',
						data: response.data.data
					});
				}
				else {
					showToast("Mise à jour non autorisée");
				}
			}
		} catch (error) {
			console.log(error.data.message);
		}		
	};
	
	useEffect(() => {
	  if (bread[bread.length-1]?.label == "Visualiser") {
			setshowSubmit(false);
	  }
	  setTimeout(() => {
		
		if (formContent) {
			for (let index = 0; index < formContent.length; index++) {
				const element = formContent[index];
				if (element.defaultValue) {
					setValue(element.label, element.defaultValue)
				}
			}
		}
	  }, 100);
	  
	}, [formContent])
	
	return(
		<div className="FormComponentWrapper">
			<div className='goBack'>
				<div className='container' onClick={()=>{tabSetter('list');setBread([bread.shift()]);}}>
				<svg viewBox="0 0 24 24">
					<path fill="currentColor" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M18,11H10L13.5,7.5L12.08,6.08L6.16,12L12.08,17.92L13.5,16.5L10,13H18V11Z" />
				</svg>
				</div>			
			</div>
			<div className='formContent'>
				<form onSubmit={handleSubmit(onSubmit)}>
					{
						formContent?.length > 0 &&
						formContent?.map(item => (
							item?.type === "area" ?
							<>
								<div className='formInput customInput'>
									<div className='input'>
										<textarea
											placeholder={item?.placeholder}
											{...register(item?.label, { required: true })}
										/>								
									</div>
									<div>
										{errors[item?.label]?.type === 'required' && <p role="alert">{ item?.placeholder } est obligatoire</p>}
									</div>
								</div>
							</>
							:
							item?.type === "select" ?
							<>
								<div className='formInput customInput'>
									<h4>
										{ item?.placeholder }
									</h4>
									<div className='input'>
										<select {...register(item?.label, { required: true })}>
											{
												item?.values?.map(choiceValue => (
													<option key={choiceValue.key} value={choiceValue.key}>{choiceValue.label}</option>
												))
											}
										</select>								
									</div>
									<div>
										{errors[item?.label]?.type === 'required' && <p role="alert">{ item?.placeholder } est obligatoire</p>}
									</div>
								</div>
							</>
							:
							item?.type.indexOf(['text', 'number', 'date']) !== 1 ?
							<>
								<div className='formInput customInput tt'>
									<div className='input'>
										<input type={item?.type} placeholder={item?.placeholder} {...register(item?.label, { required: true })} />									
									</div>
									<div>
										{errors[item?.label]?.type === 'required' && <p role="alert">{ item?.placeholder } est obligatoire</p>}
									</div>
								</div>
							</>
							:
							<></>
						)) 
					}

					{/* register your input into the hook by invoking the "register" function
					<input defaultValue="test" {...register("example")} />
					
					include validation with required or other standard HTML validation rules
					<input {...register("exampleRequired", { required: true })} />
					errors will return when field validation fails 
					{errors.exampleRequired && <span>This field is required</span>} */}
					{
						showSubmit &&
						<div className='submitDiv'>
							<input className='app-btn' type="submit" value={"Valider"} />
						</div>
					}
					
				</form>
			</div>
		</div>
	)
}

export default FormComponent