export const showToast = (message) => {
    const toastWrapper = document.createElement('div');
    toastWrapper.setAttribute('class', 'ToastWrapper')
    const messageContent = document.createElement('div');
    messageContent.setAttribute('class', 'messageContent')
    messageContent.innerHTML = message;
    toastWrapper.appendChild(messageContent);
    document.querySelector('body').appendChild(toastWrapper);
    setTimeout(() => {
        toastWrapper.remove();
    }, 3000);
}

export const showLoading = () => {
    const loadingWrapper = document.createElement('div');
    loadingWrapper.setAttribute('class', 'LoadingWrapper');
    loadingWrapper.setAttribute('id', 'LoadingWrapper');
    const spinner = document.createElement('div');
    spinner.setAttribute('class', 'lds-spinner');
    spinner.innerHTML = `<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>`;
    loadingWrapper.appendChild(spinner);
    document.querySelector('body').appendChild(loadingWrapper);
}

export const hideLoading = () => {
    document.getElementById('LoadingWrapper').remove();
}

export const ticketDays = [
    {key: 0, label: 'Lundi'},
    {key: 1, label: 'Mardi'},
    {key: 2, label: 'Mercredi'},
    {key: 3, label: 'Jeudi'},
    {key: 4, label: 'Vendredi'},
    {key: 5, label: 'Samedi'},
    {key: 6, label: 'Dimanche'},
];