
import './Welcome.scss'
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import { useEffect } from 'react';


const Welcome = () => 
{
	const navigate = useNavigate();
	const setActiveTab = (id) => {
		const allOptions = document.querySelectorAll('.menuItem');
		for (let index = 0; index < allOptions.length; index++) {
			const element = allOptions[index];
			element.classList.remove('activeMenuItem');
		}
		if (document.getElementById(id)) {
			document.getElementById(id).classList.add('activeMenuItem');
		}
	}
	useEffect(() => {
		const id = "menuItem-"+window.location.href.split('/')[4];
		if (document.getElementById(id)) {
			setActiveTab(id); 
		}
	}, [])
	
	
	return(
		<div className="WelcomeWrapper">
			<div className='toolset'>
				<div className='logo'>
					<img
						src={logo}
						onClick={()=>{ setActiveTab('menuItem-home');navigate('/dashboard/home', {replace: false}) }}
						alt='Logo'
					/>
				</div>
				<div className='menuItem activeMenuItem' id='menuItem-home' onClick={()=>{setActiveTab('menuItem-home'); navigate('/dashboard/home', {replace: false});}} style={{marginTop: '5%'}}>
					<div className='icon'>
						<svg viewBox="0 0 24 24">
							<path fill="currentColor" d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z" />
						</svg>
					</div>
					<div className='title'>
						Dashboard
					</div>
				</div>
				<div className='menuItem'  id='menuItem-utilisateurs' onClick={()=>{setActiveTab('menuItem-utilisateurs') ; navigate('/dashboard/utilisateurs/admins', {replace: false}) }}>
					<div className='icon'>
						<svg viewBox="0 0 24 24">
							<path fill="currentColor" d="M13.07 10.41A5 5 0 0 0 13.07 4.59A3.39 3.39 0 0 1 15 4A3.5 3.5 0 0 1 15 11A3.39 3.39 0 0 1 13.07 10.41M5.5 7.5A3.5 3.5 0 1 1 9 11A3.5 3.5 0 0 1 5.5 7.5M7.5 7.5A1.5 1.5 0 1 0 9 6A1.5 1.5 0 0 0 7.5 7.5M16 17V19H2V17S2 13 9 13 16 17 16 17M14 17C13.86 16.22 12.67 15 9 15S4.07 16.31 4 17M15.95 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13Z" />
						</svg>
					</div>
					<div className='title'>
						Gestion des utilisateurs
					</div>
				</div>
				<div className='menuItem'  id='menuItem-countries' onClick={()=>{setActiveTab('menuItem-countries') ; navigate('/dashboard/countries', {replace: false}) }}>
					<div className='icon'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>cash-multiple</title><path d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
					</div>
					<div className='title'>
						Pays
					</div>
				</div>
				<div className='menuItem'  id='menuItem-towns' onClick={()=>{setActiveTab('menuItem-town') ; navigate('/dashboard/towns', {replace: false}) }}>
					<div className='icon'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-open-outline</title><path d="M21 10H17V8L12.5 6.2V4H15V2H11.5V6.2L7 8V10H3C2.45 10 2 10.45 2 11V22H10V17H14V22H22V11C22 10.45 21.55 10 21 10M8 20H4V17H8V20M8 15H4V12H8V15M12 8C12.55 8 13 8.45 13 9S12.55 10 12 10 11 9.55 11 9 11.45 8 12 8M14 15H10V12H14V15M20 20H16V17H20V20M20 15H16V12H20V15Z" /></svg>					</div>
					<div className='title'>
						Villes
					</div>
				</div>
				<div className='menuItem'  id='menuItem-providers' onClick={()=>{setActiveTab('menuItem-providers') ; navigate('/dashboard/providers', {replace: false}) }}>
					<div className='icon'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>bus</title><path d="M18,11H6V6H18M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M4,16C4,16.88 4.39,17.67 5,18.22V20A1,1 0 0,0 6,21H7A1,1 0 0,0 8,20V19H16V20A1,1 0 0,0 17,21H18A1,1 0 0,0 19,20V18.22C19.61,17.67 20,16.88 20,16V6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6V16Z" /></svg>
					</div>
					<div className='title'>
						Compagnies
					</div>
				</div>
				<div className='menuItem'  id='menuItem-tickets' onClick={()=>{setActiveTab('menuItem-tickets') ; navigate('/dashboard/tickets', {replace: false}) }}>
					<div className='icon'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-open-outline</title><path d="M13,8.5H11V6.5H13V8.5M13,13H11V11H13V13M13,17.5H11V15.5H13V17.5M22,10V6C22,4.89 21.1,4 20,4H4A2,2 0 0,0 2,6V10C3.11,10 4,10.9 4,12A2,2 0 0,1 2,14V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V14A2,2 0 0,1 20,12A2,2 0 0,1 22,10Z" /></svg>					</div>
					<div className='title'>
						Tickets
					</div>
				</div>
				<div className='menuItem'  id='menuItem-documents' onClick={()=>{setActiveTab('menuItem-documents') ; navigate('/dashboard/documents', {replace: false}) }}>
					<div className='icon'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>finance</title><path d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" /></svg>					
					</div>
					<div className='title'>
						Documents
					</div>
				</div>
			</div>
			<div className='content'>
				<div className='toolbar'>
					<div className='menu'>
						<svg style={{marginRight: 30}} viewBox="0 0 24 24">
							<path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
						</svg>
						<svg viewBox="0 0 24 24" style={{marginRight: 30}}>
							<path fill="currentColor" d="M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z" />
						</svg>
						<svg viewBox="0 0 24 24" style={{marginRight: 10}}>
							<path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
						</svg>
						<span style={{marginRight: '3%'}}>
							{
								"Mr l'administrateur"
							}	
						</span>					
					</div>
				</div>
				<div className='outlet'>
					<Outlet/>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
			 
	}
}

const mapDispatchToProps = (dispatch) => {
	return {


	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Welcome)