
import './TableComponent.scss'
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react';
import SeeData from '../SeeData/SeeData';
import { deleteActions, uploadImageActions } from '../../actions/default.actions';
import { endpointsMapping } from '../../constants/endpoints.types';
import { showToast } from '../../constants/utils';


const TableComponent = ({
	settings, dataSource, icon, canEdit, canDelete, canAdd, canUpload, recordSetter, tabSetter,
	bread, setBread, numberPerPage, key, requestState, currentKey, iamgePath
}) => 
{	
	const [numberPages, setnumberPages] 				= useState(1);
	const [currentPage, setcurrentPage] 				= useState(0);
	const [dataPerPage, setdataPerPage] 				= useState([]);
	const [numberdataPerPage, setnumberdataPerPage] 	= useState(numberPerPage || 10);
	const [displayedData, setdisplayedData] 			= useState(dataSource);
	const [searchText, setsearchText] 					= useState("");
	const [showData, setshowData] 						= useState(false);
	const [dataToSee, setdataToSee]						= useState(null);
	const [currentItem, setCurrentItem]					= useState(null);

	const deleteItem = (item) => {
		Swal.fire({
			icon: 'question',
			title: 'Êtes-vous sûr de vouloir continuer ?',
			showCancelButton: true,
			cancelButtonColor: 'var(--danger-color)',
			cancelButtonText: 'NON',
			confirmButtonColor: 'var(--success-color)',
			confirmButtonText: 'OUI'
		}).then(async (response) => {
			if (response.isConfirmed) {
				sessionStorage.setItem('currentAction', 'delete');
				const request = await deleteActions(endpointsMapping.get(currentKey)['delete'], item?._id);
				if (request) {
					requestState({
						state: 'delete',
						data: { _id: item?._id }
					});
				}
			}
		})
	}
	const launchUpload = (item) => {
		setCurrentItem(item);
		document.getElementById('upload-input-table-component').click();
	}

	const startUpload = async (e) => {
		const payload = new FormData();
		payload.set('file', e.target.files[0]);
		const response = await uploadImageActions(`${endpointsMapping.get('providers')['upload']}/${currentItem._id}`, payload);
		if (response.data) {
			showToast(response.data.message)
		}
	}
	const scanTab = (e) => {
		setsearchText(e.target.value);
		if (e.target.value == "") {
			setdisplayedData(dataSource);
			return;
		}
		const temp = [];
		for (let index = 0; index < dataSource.length; index++) {
			const element = dataSource[index];
			const values = Object.values(element);
			for (let subIndex = 0; subIndex < values.length; subIndex++) {
				const subElement = values[subIndex];
				if (String(subElement).trim().toLowerCase().includes(String(e.target.value).trim().toLowerCase())) {
					temp.push(element);
					break;
				}
			}
		}
		setdisplayedData(temp);
	}
	const initTabWithNavigation = () => {
		let tabIndex = 0;
		const temp = [];
		for (let index = 0; index < Math.ceil(dataSource?.length/numberdataPerPage); index++) {
			temp.push(Array());
		}
		for (let index = 0; index < Math.ceil(dataSource?.length/numberdataPerPage); index++) {
			for (let subIndex = 0; subIndex < 10; subIndex++) {
				if (!dataSource[tabIndex]) {
					break;
				}
				temp[index] = [...temp[index], dataSource[tabIndex]]
				tabIndex++;
			}
		}
		setdataPerPage(temp);
		setdisplayedData(temp[0]);
	}
	const navigaeInTable = (option) => {
		if (option == "back") {
			setdisplayedData(dataPerPage[currentPage-1]);
			setcurrentPage(currentPage-1);
		}
		if (option == "next") {
			setdisplayedData(dataPerPage[currentPage+1]);
			setcurrentPage(currentPage+1);
		}
	}
	useEffect(() => {
		setnumberPages(Math.ceil(dataSource?.length/numberdataPerPage));
		if (Math.ceil(dataSource?.length/numberdataPerPage) > 1) {
			initTabWithNavigation();
		}
	}, [])

	return(
		<>
			{
				!showData ?
				<>
					<div className="TableComponentWrapper" key={key}>
						<table>				
							<thead>
								<tr>
									<td colSpan={settings?.length + 2}>
										<input value={searchText} onChange={scanTab}  placeholder='Rechercher un élément...' />				
									</td>
								</tr>
								<tr>
									<th></th>
									{
										settings?.length > 0 &&
										settings?.map( item =>(
												<th key={item?.label}>
													{
														item?.label
													}
												</th>
											)
										) 
									}
									<th>
										{
											canAdd &&
											<svg onClick={()=>{tabSetter('add'); setBread([...bread, {label: 'Ajouter'}])}} viewBox="0 0 24 24">
												<path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
											</svg>
										}
									</th>
								</tr>
							</thead>
							<tbody>
								{
									displayedData?.length > 0 &&
									displayedData?.map((item, index) =>(
										<tr key={item.data[index]+index+10000} >
											<td key={item.data[index]}>
												{ 
													iamgePath === undefined ?
													icon 
													:
													<>
														<img
															src={`data:image/png;base64, ${item[iamgePath]}`}
														/>
													</>
												}
											</td>
											{
												item?.data?.map( cell => (
													<td key={cell}>
														{ String(cell)?.indexOf('</') === -1 ? cell : "[Contenu]" }
													</td>							
												))
											}
											<td key={item.data[index]+index+3000}>
												<svg onClick={()=>{setdataToSee(item); setshowData(true); setBread([...bread, {label: 'Visualiser'}])}} className='view' viewBox="0 0 24 24">
													<path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
												</svg>
												{
													canEdit && 
													<svg onClick={()=>{recordSetter(item); tabSetter('update'); setBread([...bread, {label: 'Modifier'}])}} className='success' viewBox="0 0 24 24">
														<path fill="currentColor" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
													</svg>
												}
												{
													canDelete && 
													<svg onClick={()=>{deleteItem(item)}} className='danger' viewBox="0 0 24 24">
														<path fill="currentColor" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
													</svg>
												}
												{
													canUpload && 
													<svg onClick={()=>{launchUpload(item)}} className='app' viewBox="0 0 24 24">
														<path d="M6.5 20Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.88 6.85 7.75 5.43 9.63 4 12 4 14.93 4 16.96 6.04 19 8.07 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20H13Q12.18 20 11.59 19.41 11 18.83 11 18V12.85L9.4 14.4L8 13L12 9L16 13L14.6 14.4L13 12.85V18H18.5Q19.55 18 20.27 17.27 21 16.55 21 15.5 21 14.45 20.27 13.73 19.55 13 18.5 13H17V11Q17 8.93 15.54 7.46 14.08 6 12 6 9.93 6 8.46 7.46 7 8.93 7 11H6.5Q5.05 11 4.03 12.03 3 13.05 3 14.5 3 15.95 4.03 17 5.05 18 6.5 18H9V20M12 13Z" />
													</svg>
												}									
											</td>		
										</tr>
											
										)
									) 
								}
							</tbody>
						</table>
						<div className='divider'>
							<div className='numberElements'>
								<span>{ dataSource?.length }</span> document(s) retrouvés au total.
							</div>
							<div className='navigation'>
								{
									currentPage > 0 &&
									<div className='previous' onClick={()=>{navigaeInTable('back')}}>
										<svg viewBox="0 0 24 24">
											<path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
										</svg>
									</div>
								}		
								<div className='current'>
									{ currentPage+1 }
								</div>
								{
									(numberPages > 1 && currentPage != numberPages-1) &&
									<div className='next' onClick={()=>{navigaeInTable('next')}}>
										<svg viewBox="0 0 24 24">
											<path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
										</svg>
									</div>
								}
							</div>					
						</div>
					</div>
					<input
						type='file'
						style={{display: 'none'}}
						id='upload-input-table-component'
						onChange={startUpload}
					/>
				</>
				:
				<>
					<SeeData height={500} data={dataToSee} setAlertVisibility={setshowData} bread={bread} setBread={setBread} currentKey={currentKey}/>
				</>
			}
		</>
	)
}

export default TableComponent