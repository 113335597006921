export const tablesMapping = new Map();

tablesMapping.set('usersAdmin', [
        { label: "Nom", key: 'firstName' },
        { label: "Prénom", key: 'lastname' },
        { label: "Téléphone", key: 'email' },
    ]
);

tablesMapping.set('countries', [
        {label: "Nom", key: 'name'},
        {label: "Prefix", key: 'countryPhonePrefix'},
    ]
);

tablesMapping.set('tickets', [
        {label: "Source", key: 'source.name'},
        {label: "Destination", key: 'destination.name'},
        {label: "Jour", key: 'day'},
        {label: "Compagnies", key: 'provider.name'},
        {label: "Prix", key: 'price'},
    ]
);

tablesMapping.set('towns', [
        {label: "Nom", key: 'name'},
        {label: "Latitude", key: 'latitude'},
        {label: "Longitude", key: 'longitude'},
        {label: "Pays", key: 'country.name'},
    ]
);

tablesMapping.set('providers', [
        {label: "Nom", key: 'name'},
        {label: "Téléphone", key: 'phoneNumber'},
        {label: "Pays", key: 'country.name'},
        {label: "Départs", key: 'departure'},
        {label: "Arrivées", key: 'arrival'},
    ]
);

tablesMapping.set('documents', [
        {label: "Acheteur", key: 'buyerT'},
        {label: "Personnes", key: 'numberPersons'},
        {label: "Date", key: 'travelDay'},
        {label: "Départ", key: 'sourceT'},
        {label: "Destination", key: 'destinationT'},
        {label: "Compagnie", key: 'providerT'},
        {label: "Prix", key: 'transaction.amount'},
        {label: "Statut", key: 'transaction.status'},
    ]
);
