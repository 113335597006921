import axios from "axios";
import { BASE_URL } from "../constants/environnement.types";
import { hideLoading, showLoading } from "../constants/utils";

export const getAction = async (url) =>{
    try {
        showLoading();
        const response = await axios.get(`${BASE_URL}/${url}`, {
            headers: { "authorization": `Bearer ${sessionStorage.getItem('auth_token')}` }
        })
        hideLoading();
        return response;
    } catch (error) {
        hideLoading();
        console.log(error);
        return { success: false }
    }
}

export const postActions = async (url, payload) =>{
    try {
        showLoading();
        const response = await axios.post(`${BASE_URL}/${url}`, {...payload} ,{
            headers: { "authorization": `Bearer ${sessionStorage.getItem('auth_token')}` }
        })
        hideLoading();
        return response;
    } catch (error) {
        hideLoading();
        console.log(error);
        return { success: false }
    }
}

export const uploadImageActions = async (url, payload) =>{
    try {
        showLoading();
        const response = await axios.post(`${BASE_URL}/${url}`, payload ,{
            headers: {
                "Content-Type": 'multipart/form-data',
                "authorization": `Bearer ${sessionStorage.getItem('auth_token')}` 
            }
        })
        hideLoading();
        return response;
    } catch (error) {
        hideLoading();
        console.log(error);
        return { success: false }
    }
}

export const putActions = async (url, payload) =>{
    try {
        showLoading();
        const response = await axios.put(`${BASE_URL}/${url}/${payload?.id}`, {...payload} ,{
            headers: { "authorization": `Bearer ${sessionStorage.getItem('auth_token')}` }
        })
        hideLoading();
        return response;
    } catch (error) {
        hideLoading();
        console.log(error);
        return { success: false }
    }
}

export const deleteActions = async (url, id) =>{
    try {
        showLoading();
        const response = await axios.delete(`${BASE_URL}/${url}/${id}` ,{
            headers: { "authorization": `Bearer ${sessionStorage.getItem('auth_token')}` }
        })
        hideLoading();
        return response;
    } catch (error) {
        hideLoading();
        console.log(error);
        return { success: false }
    }
}

export const transformDataForTableComponent = (settings, data) => {
    const keysToRetrieve = [];
    for (let index = 0; index < settings.length; index++) {
        const element = settings[index];
        keysToRetrieve.push(element?.key);
    }
    const temp = data;
    for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        element.data = [];
        for (let subIndex = 0; subIndex < keysToRetrieve.length; subIndex++) {
            const key = keysToRetrieve[subIndex];
            if (String(key).split('.').length > 1) {
                element.data.push(
                    element[`${String(key).split('.')[0]}`][`${String(key).split('.')[1]}`]
                )
            } else {
                element.data.push(element[`${key}`])
            }
        }
    }
    return temp;
}

export const transformSingleDataForTableComponent = (settings, data) => {
    const keysToRetrieve = [];
    for (let index = 0; index < settings.length; index++) {
        const element = settings[index];
        keysToRetrieve.push(element?.key);
    }
    const temp = data;
    temp.data = []
    for (let subIndex = 0; subIndex < keysToRetrieve.length; subIndex++) {
        const key = keysToRetrieve[subIndex];
        temp.data.push(temp[`${key}`])
    }
    return temp;
}

export const updateComponentKey = (currentKey) => {
    return `${currentKey.split('-')[0]}-${Number(currentKey.split('-')[1])+1}`
}


