import { BrowserRouter, Route, Routes,  } from "react-router-dom";
import Users from "./pages/Users/Users"
import Welcome from "./pages/Welcome/Welcome"
import Home from "./pages/Home/Home"
import Auth from './pages/Auth/Auth';
import './App.scss';
import NewClient from "./pages/NewClient/NewClient";
import Countries from "./pages/Pays/countries";
import Towns from "./pages/Ville/towns";
import Providers from "./pages/Compagnie/providers";
import Tickets from "./pages/Ticket/tickets";
import Documents from "./pages/Documents/Documents";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Auth />}/>
            <Route path="/dashboard" element={<Welcome />}>
                <Route path="home" element={<Home />} />
                <Route path="clients" element={<NewClient />} />
                <Route path="utilisateurs">
                  <Route path="admins" element={<Users />} />
                  <Route path="travailleurs" element={<Users />} />
                </Route>
                <Route path="countries" element={<Countries />} />
                <Route path="towns" element={<Towns />} />
                <Route path="tickets" element={<Tickets />} />
                <Route path="providers" element={<Providers />} />
                <Route path="documents" element={<Documents />} />
            </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
