
import './Documents.scss'
import NotFound from "../../components/NotFound/NotFound";
import { useEffect, useState } from 'react';
import BreadCumb from '../../components/BreadCumb/BreadCumb';
import FormComponent from '../../components/FormComponent/FormComponent';
import TableComponent from '../../components/TableComponent/TableComponent'
import { getAction, transformDataForTableComponent, updateComponentKey } from '../../actions/default.actions';
import { endpointsMapping } from '../../constants/endpoints.types';
import { formsMapping } from '../../constants/formDatas.types';
import { tablesMapping } from "../../constants/tablesSettings.types";


const Documents = () => 
{
	const [tableComponentKey, settableComponentKey] = useState("documents");
	const [currentOption, setcurrentOption] = useState("list");
	const [record, recordSetter] = useState(null);
	const [retreivedData, setRetrievedData] = useState([]);
	const [formData, setFormData] = useState(null);
	const [formContent, setFormContent] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [addedOrUpdatedData, setAddedOrUpdatedData] = useState({state: '', data:{}});
	const [bread, setBread] = useState([{label: "documents", key: 'documents1'}]);
	const tableIcon = (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>finance</title><path d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" /></svg>
	)
	const settings = tablesMapping.get(tableComponentKey);
	const _retrieveData = () => {
		const documentsPromise = new Promise(async (resolve, reject) => {
			const response = await getAction(endpointsMapping.get(tableComponentKey)['get']);
			resolve(response);
		})
		return [documentsPromise]; 
	}
	useEffect(() => {
		Promise.all(_retrieveData()).then((values) => {
			const data = values[0];
			if(data?.data.length > 0)
			{
				const temp = data?.data;
				for (let index = 0; index < temp.length; index++) {
					const element = temp[index];
					element.buyerT = `${element.buyer.firstName} ${element.buyer.lastName}`;
					element.sourceT = element.ticket.source.name;
					element.destinationT = element.ticket.destination.name;
					element.providerT = element.ticket.provider.name;
				}
				setRetrievedData(temp);
				const transData = transformDataForTableComponent(settings, temp);
				setDataSource(transData);
			}
		});
		setFormContent(formsMapping.get(tableComponentKey).resolveMapping());
	}, [])
	useEffect(() => {
		if (record) {
			for (let index = 0; index < formContent.length; index++) {
				const element = formContent[index];
				element.defaultValue = record?.[element?.['label']]
			}
		}		
	}, [record])
	useEffect(() => {
		if (currentOption === "list") {
			for (let index = 0; index < formContent.length; index++) {
				const element = formContent[index];
				delete element.defaultValue;
			}
		}
	  }, [currentOption])
	  useEffect(() => {
		if (addedOrUpdatedData.state !== "") {
			if (addedOrUpdatedData.state === "create") {
				setRetrievedData([...retreivedData, addedOrUpdatedData.data])
				setDataSource([...dataSource, ...transformDataForTableComponent(settings, [addedOrUpdatedData.data])]);
				setcurrentOption('list');
			}
			if (addedOrUpdatedData.state === "update") {
				const foundIndex = retreivedData.findIndex(data => data._id === addedOrUpdatedData.data._id);
				const temp = [...retreivedData];
				temp[foundIndex] = addedOrUpdatedData.data;
				const transData = transformDataForTableComponent(settings, temp);
				setDataSource(transData);
				setcurrentOption('list');
			}
			if (addedOrUpdatedData.state === "delete") {
				const foundIndex = retreivedData.findIndex(data => data._id === addedOrUpdatedData.data._id);
				const temp = [...retreivedData];
				temp.splice(foundIndex, 1);
				const transData = transformDataForTableComponent(settings, temp);
				setDataSource(transData);
				setTimeout(() => {
					setcurrentOption('add');
					setcurrentOption('list');
				}, 100);
			}
		}
	  }, [addedOrUpdatedData])
	useEffect(() => {
		document.querySelector('.outlet').style.overflowY = "auto";
	}, [])
	return(
		<div className="DocumentsWrapper custom-wrapper">
			<BreadCumb situation={bread} />
			<div className='spacer'></div>
			{
				currentOption === "list" && dataSource.length > 0 ?
				<TableComponent 
					key={tableComponentKey}
					bread={bread}
					requestState={setAddedOrUpdatedData}
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					recordSetter={recordSetter} 
					icon={tableIcon} 
					settings={settings} 
					dataSource={dataSource} 
					canEdit={false} 
					canDelete={true} 
					canAdd={false}
					currentKey={tableComponentKey}
				/>
				:
				currentOption === "add" ?
				<FormComponent
					key={"clients-formContent"} 
					formContent={formContent} 
					setFormData={setFormData} 
					bread={bread} 
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					post={endpointsMapping.get(tableComponentKey)['post']}
					type="post"
					requestState={setAddedOrUpdatedData}
				/>
				:
				dataSource.length > 0 ?
				<FormComponent 
					formContent={formContent} 
					formData={formData} 
					setFormData={setFormData} 
					bread={bread} 
					setBread={setBread} 
					tabSetter={setcurrentOption} 
					record={record}
					put={endpointsMapping.get(tableComponentKey)['put']}
					type="put"
					requestState={setAddedOrUpdatedData}
				/>
				:
				<>
					<TableComponent bread={bread} setBread={setBread} tabSetter={setcurrentOption} recordSetter={recordSetter} icon={tableIcon} settings={settings} dataSource={dataSource} canEdit={false} canDelete={true} canAdd={true} />
					<NotFound/>
				</>
			}
		</div>
	)
}

export default Documents