
import { useEffect, useState } from 'react';
import './SeeData.scss'
import { tablesMapping } from '../../constants/tablesSettings.types';


const SeeData = ({setAlertVisibility, data, setBread, bread, currentKey}) => 
{	
	useEffect(() => {
		
	}, [])
	
	return(
		<div className="SeeDataWrapper">
			<div className='goBack'>
				<div className='container' onClick={()=>{setAlertVisibility(false);setBread([bread.shift()]);}}>
				<svg viewBox="0 0 24 24">
					<path fill="currentColor" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M18,11H10L13.5,7.5L12.08,6.08L6.16,12L12.08,17.92L13.5,16.5L10,13H18V11Z" />
				</svg>
				</div>			
			</div>
			{/* <div className='alertBackdrop'></div>
			<div className='modal' style={{left: '-12%', top: -40}}>
				<div className='close' style={{width: '180%'}}>
					<svg onClick={()=>{setAlertVisibility(false);}} viewBox="0 0 24 24">
						<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
					</svg>
				</div>
				<div className='content' style={{height: "80%", width: "170%",}}>
						<h2 className='title'>
								Toutes les informations disponibles
						</h2>		
						<div className='displayData'>							
							{
								Object.keys(data)?.map(key =>(
									<div key={key} className='item'>
										{
											(key != "data" && String(key).length > 0 ) &&
											<>
												<div className='title'>
													{key}
												</div>
												<div className='value'>
													{ data[key] }
												</div>
											</>
										}
									</div>
								))
							}
						</div>		
				</div>				
			</div> */}
			<div className='infosCard'>
				<div className='header'>
					Détails document
				</div>
				<div className='body'>
					{
						tablesMapping.get(currentKey)?.map(key =>(
							<div key={key?.key} className='item'>
								<div className='title'>
									{ key?.label } :
								</div>
								<div className='value'>
									{ 
										String(key?.key)?.split('.').length > 1 ? 
										String(data[`${String(key?.key)?.split('.')[0]}`][`${String(key?.key)?.split('.')[1]}`])?.indexOf('</') === -1 ? data[`${String(key?.key)?.split('.')[0]}`][`${String(key?.key)?.split('.')[1]}`] : "[Contenu]"
										:
										String(data[`${key?.key}`])?.indexOf('</') === -1 ? data[`${key?.key}`] : "[Contenu]"
									}
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

export default SeeData